import { ContentPageType } from '../models/content-page-type';
import withCache from '../services/cache/with-cache';
import pageContent from '../services/content-api/endpoints/page/content';

const getContentPage = async (
  path: string,
  preview?: boolean,
  previewToken?: string | string[]
): Promise<ContentPageType> => {
  return preview
    ? pageContent(path, preview, previewToken)
    : withCache(`page.${path}`, async () =>
        pageContent(path, preview, previewToken)
      );
};

export default getContentPage;
