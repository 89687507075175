import axiosRetry from 'axios-retry';
import { ContentPageType } from '../../../../models/content-page-type';
import CultureCodeEnum from '../../../../models/culture-code-enum';
import client from '../../client-unauthenticated';

axiosRetry(client, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const pageContent = async (
  path: string,
  preview?: boolean,
  previewToken?: string | string[]
): Promise<ContentPageType> => {
  const { data } = await client.get<ContentPageType>('/page/content', {
    params: {
      cultureCode: CultureCodeEnum.EnGb,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path,
      preview,
      previewToken,
    },
  });

  return data;
};

export default pageContent;
