import cache from 'memory-cache';

export default async function withCache<T = unknown>(
  key: string,
  fn: () => Promise<T>,
  ttlSeconds: number = 5 * 60
): Promise<T> {
  const isProduction = process.env.NODE_ENV === 'production';
  let value = cache.get(key);

  if (!value || !isProduction) {
    value = await fn();
    cache.put(key, value, ttlSeconds * 1000);
  }

  return value;
}
