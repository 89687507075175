import React from 'react';
import { NextPage } from 'next';
import ContentPage from '../components/templates/content-page/content-page';
import { ContentPageType } from '../models/content-page-type';
import getContentPage from '../helpers/get-content-page';

interface Props {
  readonly page: ContentPageType | null;
}

const Error: NextPage<Props> = ({ page }: Props) => {
  return <ContentPage {...page} />;
};

Error.getInitialProps = async ({ err }) => {
  const page = await getContentPage(`/${err ? err.statusCode : 404}`);

  return {
    page,
  };
};

export default Error;
